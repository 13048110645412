@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter", sans-serif;
  src: url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
}

:root {
  --mouse-x: 0;
  --mouse-y: 0;
}

.btn {
  border-width: 0.5rem;
  border-radius: 50% 50%;
  /* padding: 0.5rem 1rem; */
  display: inline-block;
  text-align: center;
}

.markdown-post h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.markdown-post h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

.markdown-post p {
  margin-bottom: 1rem;
  line-height: 1.6;
}

.markdown-post strong {
  font-weight: bold;
}

.markdown-post em {
  font-style: italic;
}


